<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <p class="parrafo mt-10">
        1.- <b>Send this request to your sales executive.</b> (Envíe este formato a su ejecutivo comercial).
      </p>
      <p class="parrafo mt-10">
        2.- <b>All the information that is filled out in this application must be in English language.</b>
        (Toda la información que se llene en esta solicitud debe estar en idioma inglés).
      </p>
      <p class="parrafo mt-10">
        3.- <b>Send a mail to the supplier informing them that Asia IBS will contact them to coordinate the
          visit.</b>
          (A su vez, envíe un correo electrónico a su proveedor indicando que "Asia IBS" lo contactará para programar
          la fecha de la visita).
      </p>
      <p class="parrafo mt-10">
        4.- <b>Any field with a "*" is required.</b> (Cada campo con un signo de "*"" es obligatorio).
      </p>
      <p class="parrafo mt-10">
        5.- <b>The report will be shared within 48 effective hours after the inspection.</b>
        (El reporte se entregará en un periodo de 48 hrs. hábiles después de hacerse la inspección).
      </p>
    </div>

    <div class="column is-12">
      <h2 class="subtitle">
        CONSIGNEE INFORMATION (Datos del consignatario)
      </h2>
    </div>
    <div class="column is-3">
      <label class="label">*Company name:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('companyName')}"
            type="text"
            name="companyName"
            v-model="companyName"
            placeholder="Company name"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Contact person:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('customerContactPerson')}"
            type="text"
            name="customerContactPerson"
            v-model="customerContactPerson"
            placeholder="Contact person"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">E-mail address:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('customerEmail')}"
            type="text"
            name="customerEmail"
            v-model="customerEmail"
            placeholder="E-mail address"
            v-validate="'required|email'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Telephone:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('customerPhone')}"
            type="text"
            name="customerPhone"
            v-model="customerPhone"
            placeholder="Telephone"
            v-validate="'required'"
            maxlength="15"
            @keypress="onlyNumbers()"
          >
        </div>
      </div>
    </div>

    <div class="column is-12">
      <h2 class="subtitle">
        SUPPLIER INFORMATION (Datos del proveedor)
      </h2>
    </div>
    <div class="column is-3">
      <label class="label">*Supplier’s name:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierName')}"
            type="text"
            name="supplierName"
            v-model="supplierName"
            placeholder="Supplier’s name"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Contact person:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierContactPerson')}"
            type="text"
            name="supplierContactPerson"
            v-model="supplierContactPerson"
            placeholder="Contact person"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Personal e-mail:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierPersonalEmail')}"
            type="text"
            name="supplierPersonalEmail"
            v-model="supplierPersonalEmail"
            placeholder="Personal e-mail"
            v-validate="'required|email'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Personal phone:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierPersonalPhone')}"
            type="text"
            name="supplierPersonalPhone"
            v-model="supplierPersonalPhone"
            placeholder="Personal phone"
            v-validate="'required'"
            maxlength="15"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">[WeChat, QQ]:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierPersonalWechat')}"
            type="text"
            name="supplierPersonalWechat"
            v-model="supplierPersonalWechat"
            placeholder="Personal phone"
            v-validate="'required'"
            maxlength="15"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Company phone:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierCompanyPhone')}"
            type="text"
            name="supplierCompanyPhone"
            v-model="supplierCompanyPhone"
            placeholder="Company phone"
            v-validate="'required'"
            maxlength="15"
            @keypress="onlyNumbers()"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Address:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierAddress')}"
            type="text"
            name="supplierAddress"
            v-model="supplierAddress"
            placeholder="Address"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-12">
      <h2 class="subtitle">
        PURCHASE ORDER DETAILS (Detalles de la orden de compra)
      </h2>
    </div>
    <div class="column is-3">
      <label class="label">*Purchase order number:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('purchaseOrderNumber')}"
            type="text"
            name="purchaseOrderNumber"
            v-model="purchaseOrderNumber"
            placeholder="Purchase order number"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Product’s name:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('productName')}"
            type="text"
            name="productName"
            v-model="productName"
            placeholder="Product’s name"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Total order quantity (pcs):</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('totalOrderQuantity')}"
            type="text"
            name="totalOrderQuantity"
            v-model="totalOrderQuantity"
            placeholder="Total order quantity (pcs)"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-12">
      <h2 class="subtitle">
        INSPECTION DETAILS (Detalles de la inspección)
      </h2>
    </div>
    <div class="column is-3">
      <label class="label">Inspection standard:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('inspectionStandard')}"
            type="text"
            name="inspectionStandard"
            v-model="inspectionStandard"
            placeholder="Inspection standard"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Type of inspection:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('typeOfInspection')}"
            type="text"
            name="typeOfInspection"
            v-model="typeOfInspection"
            placeholder="Type of inspection"
          >
        </div>
      </div>
    </div>

    <div class="column is-12">
      <h2 class="subtitle">
        PRODUCT CHECK LIST (Características del producto)
      </h2>
    </div>
    <div class="column is-3">
      <label class="label">Item’s name:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('productItemName')}"
            type="text"
            name="productItemName"
            v-model="productItemName"
            placeholder="Item’s name"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Product size:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('productSize')}"
            type="text"
            name="productSize"
            v-model="productSize"
            placeholder="Product size"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Product weight:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('productWeight')}"
            type="text"
            name="productWeight"
            v-model="productWeight"
            placeholder="Product weight"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Product color (Pantone):</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('productColor')}"
            type="text"
            name="productColor"
            v-model="productColor"
            placeholder="Product color (Pantone)"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Packaging size:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('productPackagingSize')}"
            type="text"
            name="productPackagingSize"
            v-model="productPackagingSize"
            placeholder="Packaging size"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Packaging weight:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('productPacagingWieht')}"
            type="text"
            name="productPacagingWieht"
            v-model="productPacagingWieht"
            placeholder="Packaging weight"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Packaging color (Pantone):</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('productPackagingColor')}"
            type="text"
            name="productPackagingColor"
            v-model="productPackagingColor"
            placeholder="Packaging color (Pantone)"
          >
        </div>
      </div>
    </div>

    <div class="column is-12">
      <h2 class="subtitle">
        DEFECTS DETAILS (Detalles de los defectos)
      </h2>
      <p>
        Lorem ipsum
      </p>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Product small scratches:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductSmallScratches"
                v-model="ProductSmallScratches"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductSmallScratches"
                v-model="ProductSmallScratches"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductSmallScratches"
                v-model="ProductSmallScratches"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Product big scratches:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductBigScratches"
                v-model="ProductBigScratches"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductBigScratches"
                v-model="ProductBigScratches"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductBigScratches"
                v-model="ProductBigScratches"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Product size +/- 5%:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductSize5"
                v-model="ProductSize5"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductSize5"
                v-model="ProductSize5"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductSize5"
                v-model="ProductSize5"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Product size +/- 10%:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductSize10"
                v-model="ProductSize10"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductSize10"
                v-model="ProductSize10"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="ProductSize10"
                v-model="ProductSize10"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Product dirty:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productDirty"
                v-model="productDirty"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productDirty"
                v-model="productDirty"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productDirty"
                v-model="productDirty"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Product broken:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productBroken"
                v-model="productBroken"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productBroken"
                v-model="productBroken"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productBroken"
                v-model="productBroken"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Product damaged:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productDamaged"
                v-model="productDamaged"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productDamaged"
                v-model="productDamaged"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productDamaged"
                v-model="productDamaged"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Product color:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productColorDefect"
                v-model="productColorDefect"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productColorDefect"
                v-model="productColorDefect"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productColorDefect"
                v-model="productColorDefect"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Product labels differences:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productLabelsDifferences"
                v-model="productLabelsDifferences"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productLabelsDifferences"
                v-model="productLabelsDifferences"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productLabelsDifferences"
                v-model="productLabelsDifferences"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Product logo defects:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productLogoDefects"
                v-model="productLogoDefects"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productLogoDefects"
                v-model="productLogoDefects"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productLogoDefects"
                v-model="productLogoDefects"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Packing small scratches:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingSmallScratches"
                v-model="packingSmallScratches"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingSmallScratches"
                v-model="packingSmallScratches"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingSmallScratches"
                v-model="packingSmallScratches"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Packing big scratches:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingBigScratches"
                v-model="packingBigScratches"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingBigScratches"
                v-model="packingBigScratches"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingBigScratches"
                v-model="packingBigScratches"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Packing size +/- 5%:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingSize5"
                v-model="packingSize5"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingSize5"
                v-model="packingSize5"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingSize5"
                v-model="packingSize5"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Packing size +/- 10%:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingSize10"
                v-model="packingSize10"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingSize10"
                v-model="packingSize10"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingSize10"
                v-model="packingSize10"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Packing dirty:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingDirty"
                v-model="packingDirty"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingDirty"
                v-model="packingDirty"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingDirty"
                v-model="packingDirty"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Packing broken:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingBroken"
                v-model="packingBroken"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingBroken"
                v-model="packingBroken"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingBroken"
                v-model="packingBroken"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Packing damaged:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingDamaged"
                v-model="packingDamaged"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingDamaged"
                v-model="packingDamaged"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingDamaged"
                v-model="packingDamaged"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Packing color:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingColor"
                v-model="packingColor"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingColor"
                v-model="packingColor"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingColor"
                v-model="packingColor"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Packing labels differences:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingLabelsDifferences"
                v-model="packingLabelsDifferences"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingLabelsDifferences"
                v-model="packingLabelsDifferences"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingLabelsDifferences"
                v-model="packingLabelsDifferences"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Packing logo problems:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingLogoProblems"
                v-model="packingLogoProblems"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingLogoProblems"
                v-model="packingLogoProblems"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingLogoProblems"
                v-model="packingLogoProblems"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Packing bar code:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingBarCode"
                v-model="packingBarCode"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingBarCode"
                v-model="packingBarCode"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="packingBarCode"
                v-model="packingBarCode"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Production quantity +/- 5%:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productionQuantity5"
                v-model="productionQuantity5"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productionQuantity5"
                v-model="productionQuantity5"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productionQuantity5"
                v-model="productionQuantity5"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Production quantity +/- 10%:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productionQuantity10"
                v-model="productionQuantity10"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productionQuantity10"
                v-model="productionQuantity10"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="productionQuantity10"
                v-model="productionQuantity10"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Accessories not completed:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="accessoriesNotCompleted"
                v-model="accessoriesNotCompleted"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="accessoriesNotCompleted"
                v-model="accessoriesNotCompleted"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="accessoriesNotCompleted"
                v-model="accessoriesNotCompleted"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-mobile">
        <div class="column is-6">
          Function tests:
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="functionTest"
                v-model="functionTest"
                value="Minor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="functionTest"
                v-model="functionTest"
                value="Mayor"
              />
            </label>
          </div>
        </div>
        <div class="column is-2">
          <div class="control">
            <label class="radio">
              <input
                type="radio"
                name="functionTest"
                v-model="functionTest"
                value="Critical"
              />
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="column is-12">
      <h2 class="subtitle">
        SPECIAL REQUIREMENTS OR INSTRUCTIONS (Instrucciones o requerimientos específicos)
      </h2>
    </div>
    <div class="column is-12">
      <div class="field">
        <div class="control">
          <textarea
            class="textarea"
            :class="{'is-danger': vverrors.has('newMessage')}"
            rows="3"
            name="newMessage"
            placeholder=""
          ></textarea>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <documents :documentList="documentList"/>
    </div>
    <div class="column is-12">
      <div class="control">
        <button
          class="button button_ibs is-rounded sm_btn is-pulled-right"
          :class="{'is-loading': loading}"
          @click="confirmRequest"
          :disabled="loading"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Documents from '../../../components/services/Documents.vue'
export default {
  props: {
    supplierData: {
      type: Object,
      default: () => {}
    },
    myCustomerDetail: {
      type: Object,
      default: () => {}
    },
    sendRequestService: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    Documents
  },
  data () {
    return {
      supplierName: null,
      supplierContactPerson: null,
      supplierPersonalEmail: null,
      supplierPersonalPhone: null,
      supplierPersonalWechat: null,
      supplierCompanyPhone: null,
      supplierAddress: null,
      companyName: null,
      customerContactPerson: null,
      customerEmail: null,
      customerPhone: null,
      purchaseOrderNumber: null,
      productName: null,
      totalOrderQuantity: null,
      inspectionStandard: null,
      typeOfInspection: null,
      productItemName: null,
      productSize: null,
      productWeight: null,
      productColor: null,
      productPackagingSize: null,
      productPacagingWieht: null,
      productPackagingColor: null,
      ProductSmallScratches: null,

      ProductBigScratches: null,
      ProductSize5: null,
      ProductSize10: null,
      productDirty: null,
      productBroken: null,
      productDamaged: null,
      productColorDefect: null,
      productLabelsDifferences: null,
      productLogoDefects: null,
      packingSmallScratches: null,
      packingBigScratches: null,
      packingSize5: null,
      packingSize10: null,
      packingDirty: null,
      packingBroken: null,
      packingColor: null,
      packingLabelsDifferences: null,
      packingLogoProblems: null,
      packingBarCode: null,
      productionQuantity5: null,
      productionQuantity10: null,
      accessoriesNotCompleted: null,
      functionTest: null,

      specialRequirements: null,
      loading: false,
      documentList: []
    }
  },
  mounted () {
    this.supplierName = this.supplierData.name
    this.supplierContactPerson = this.supplierData.contact
    this.supplierPersonalEmail = this.supplierData.mail
    this.supplierPersonalPhone = this.supplierData.phone
    this.supplierAddress = this.supplierData.address
    this.companyName = this.myCustomerDetail.name
    this.customerContactPerson = this.myCustomerDetail.contact
    this.customerEmail = this.myCustomerDetail.email
    this.customerPhone = this.myCustomerDetail.phone
  },
  methods: {
    onlyNumbers (evt) {
      evt = (!evt) ? window.event : evt
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async confirmRequest () {
      const data = {
        supplierName: this.supplierName,
        supplierContactPerson: this.supplierContactPerson,
        supplierPersonalEmail: this.supplierPersonalEmail,
        supplierPersonalPhone: this.supplierPersonalPhone,
        supplierPersonalWechat: this.supplierPersonalWechat,
        supplierCompanyPhone: this.supplierCompanyPhone,
        supplierAddress: this.supplierAddress,
        companyName: this.companyName,
        customerContactPerson: this.customerContactPerson,
        customerEmail: this.customerEmail,
        customerPhone: this.customerPhone,

        purchaseOrderNumber: this.purchaseOrderNumber,
        productName: this.productName,
        totalOrderQuantity: this.totalOrderQuantity,
        inspectionStandard: this.inspectionStandard,
        typeOfInspection: this.typeOfInspection,
        productItemName: this.productItemName,
        productSize: this.productSize,
        productWeight: this.productWeight,
        productColor: this.productColor,
        productPackagingSize: this.productPackagingSize,
        productPacagingWieht: this.productPacagingWieht,
        productPackagingColor: this.productPackagingColor,
        ProductSmallScratches: this.ProductSmallScratches,
        ProductBigScratches: this.ProductBigScratches,
        ProductSize5: this.ProductSize5,
        ProductSize10: this.ProductSize10,
        productDirty: this.productDirty,
        productBroken: this.productBroken,
        productDamaged: this.productDamaged,
        productColorDefect: this.productColorDefect,
        productLabelsDifferences: this.productLabelsDifferences,
        productLogoDefects: this.productLogoDefects,
        packingSmallScratches: this.packingSmallScratches,
        packingBigScratches: this.packingBigScratches,
        packingSize5: this.packingSize5,
        packingSize10: this.packingSize10,
        packingDirty: this.packingDirty,
        packingBroken: this.packingBroken,
        packingColor: this.packingColor,
        packingLabelsDifferences: this.packingLabelsDifferences,
        packingLogoProblems: this.packingLogoProblems,
        packingBarCode: this.packingBarCode,
        productionQuantity5: this.productionQuantity5,
        productionQuantity10: this.productionQuantity10,
        accessoriesNotCompleted: this.accessoriesNotCompleted,
        functionTest: this.functionTest,
        specialRequirements: this.specialRequirements,
        documentList: this.documentList
      }
      this.loading = true
      if (await this.$validator.validateAll()) {
        await this.sendRequestService(data)
      }
      this.loading = false
    }
  }
}
</script>
