<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <p class="parrafo mt-10 is-red">
        1.- <b>Please send the PI or Packing List along with this application form. We should receive this
          at least 48 effective hours before the inspection.</b>
          (Favor de enviar PI y/o lista de empaque acompañados de esta solicitud por lo menos 48 hrs. hábiles antes de la carga).
      </p>
      <p class="parrafo mt-10">
        2.- <b>Any field with a "*" is required.</b> (Cada campo con un signo de "*" es obligatorio).
      </p>
      <p class="parrafo mt-10">
        3.- <b>All the information that is filled out in this application must be in English language.</b>
        (Toda la información que se llene en esta solicitud debe estar en idioma inglés).
      </p>
      <p class="parrafo mt-10">
        4.- <b>Please send an email to the supplier informing and authorizing Asia IBS to perform this loading inspection.</b>
        (Favor de notificar al proveedor y autorizar que Asia IBS haga la inspección).
      </p>
      <p class="parrafo mt-10">
        5.- <b>The report will be shared within 48-72 effective hours. after the loading only if a previous PI
          or PL is sent along with this format, otherwise we must wait until the shipper shares the final PL.</b>
          (El reporte se entrega en un periodo de 48-72 hrs. hábiles siempre y cuando se envíe una PI o PL con esta solicitud,
          de lo contrario debemos esperar a que el shipper nos comparta la PL final).
      </p>
      <p class="parrafo mt-10">
        6.- <b>If you require a support plan to implement corrective actions during the loading, you can
          approach your executive to make a contingency plan.</b>
          (En caso de requerir un plan de apoyo para implementar acciones correctivas durante la carga,
          podrá acercarse con su ejecutivo para elaborar un plan de contingencias).
      </p>
    </div>
    <div class="column is-12">
      <h2 class="subtitle">
        CONSIGNEE INFORMATION (Datos del consignatario)
      </h2>
    </div>
    <div class="column is-3">
      <label class="label">*Company name:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('companyName')}"
            type="text"
            name="companyName"
            v-model="companyName"
            placeholder="Company name"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Contact person:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('customerContactPerson')}"
            type="text"
            name="customerContactPerson"
            v-model="customerContactPerson"
            placeholder="Contact person"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">E-mail address:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('customerEmail')}"
            type="text"
            name="customerEmail"
            v-model="customerEmail"
            placeholder="E-mail address"
            v-validate="'required|email'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Telephone:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('customerPhone')}"
            type="text"
            name="customerPhone"
            v-model="customerPhone"
            placeholder="Telephone"
            v-validate="'required'"
            maxlength="15"
            @keypress="onlyNumbers()"
          >
        </div>
      </div>
    </div>

    <div class="column is-12">
      <h2 class="subtitle">
        SUPPLIER INFORMATION (Datos del proveedor)
      </h2>
    </div>
    <div class="column is-3">
      <label class="label">*Supplier’s name:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierName')}"
            type="text"
            name="supplierName"
            v-model="supplierName"
            placeholder="Supplier’s name"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Contact person:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierContactPerson')}"
            type="text"
            name="supplierContactPerson"
            v-model="supplierContactPerson"
            placeholder="Contact person"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Personal e-mail:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierPersonalEmail')}"
            type="text"
            name="supplierPersonalEmail"
            v-model="supplierPersonalEmail"
            placeholder="Personal e-mail"
            v-validate="'required|email'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Personal phone:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierPersonalPhone')}"
            type="text"
            name="supplierPersonalPhone"
            v-model="supplierPersonalPhone"
            placeholder="Personal phone"
            v-validate="'required'"
            maxlength="15"
            >
            <!-- @keypress="onlyNumbers()" -->
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">[WeChat, QQ]:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierPersonalWechat')}"
            type="text"
            name="supplierPersonalWechat"
            v-model="supplierPersonalWechat"
            placeholder="WeChat, QQ"
            v-validate="'required'"
            maxlength="15"
            >
            <!-- @keypress="onlyNumbers()" -->
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Company phone:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierCompanyPhone')}"
            type="text"
            name="supplierCompanyPhone"
            v-model="supplierCompanyPhone"
            placeholder="Company phone"
            maxlength="15"
            @keypress="onlyNumbers()"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Address:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierAddress')}"
            type="text"
            name="supplierAddress"
            v-model="supplierAddress"
            placeholder="Address"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>

    <div class="column is-12">
      <h2 class="subtitle">
        FORWARDER INFORMATION (Agente de carga en origen)
      </h2>
    </div>
    <div class="column is-3">
      <label class="label">*Forwarder’s name:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('forwarderName')}"
            type="text"
            name="forwarderName"
            v-model="forwarderName"
            placeholder="Forwarder’s name"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Contact person:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('forwarderContactPerson')}"
            type="text"
            name="forwarderContactPerson"
            v-model="forwarderContactPerson"
            placeholder="Contact person"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Personal e-mail:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('forwarderPersonalEmail')}"
            type="text"
            name="forwarderPersonalEmail"
            v-model="forwarderPersonalEmail"
            placeholder="Personal e-mail"
            v-validate="'email'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Personal phone:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('forwarderPersonalPhone')}"
            type="text"
            name="forwarderPersonalPhone"
            v-model="forwarderPersonalPhone"
            placeholder="Personal phone"
            maxlength="15"
            >
            <!-- @keypress="onlyNumbers()" -->
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">[WeChat, QQ]:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('forwarderPersonalWechat')}"
            type="text"
            name="forwarderPersonalWechat"
            v-model="forwarderPersonalWechat"
            placeholder="WeChat, QQ"
            maxlength="15"
            >
            <!-- @keypress="onlyNumbers()" -->
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Company phone:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('forwarderCompanyPhone')}"
            type="text"
            name="forwarderCompanyPhone"
            v-model="forwarderCompanyPhone"
            placeholder="Company phone"
          >
        </div>
      </div>
    </div>

    <div class="column is-12">
      <h2 class="subtitle">
        LOADING DETAILS (Detalles de la carga)
      </h2>
    </div>
    <div class="column is-3">
      <label class="label">*PO number:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('poNumber')}"
            type="text"
            name="poNumber"
            v-model="poNumber"
            placeholder="PO number"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Total carton quantity:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('totalCartonQuantity')}"
            type="text"
            name="totalCartonQuantity"
            v-model="totalCartonQuantity"
            placeholder="Total carton quantity"
            @keypress="onlyNumbers()"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Type &amp; quantity of containers:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('typeQuantityContainers')}"
            type="text"
            name="typeQuantityContainers"
            v-model="typeQuantityContainers"
            placeholder="Type &amp; quantity of containers"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Loading Date:</label>
      <div class="field">
        <div class="control has-icons-right" :class="{ 'is-danger-calendar': vverrors.has('loadingDate') }">
          <v-date-picker
            class="input_calendar"
            clearable
            v-model='loadingDate'
            name="loadingDate"
            :input-props='{ placeholder: "Loading Date" }'
          />
        </div>
      </div>
    </div>
    <div class="column is-12">
      <label class="label">Products description:</label>
      <div class="field">
        <div class="control">
          <textarea
            class="textarea"
            :class="{'is-danger': vverrors.has('productDescription')}"
            rows="3"
            name="productDescription"
            v-model="productDescription"
            placeholder="Products description…"
          ></textarea>
        </div>
      </div>
    </div>

    <div class="column is-12">
      <h2 class="subtitle">
        SPECIAL REQUIREMENTS OR INSTRUCTIONS (Instrucciones o requerimientos específicos)
      </h2>
    </div>
    <div class="column is-12">
      <!-- <label class="label">Products description:</label> -->
      <div class="field">
        <div class="control">
          <textarea
            class="textarea"
            :class="{'is-danger': vverrors.has('specialRequirements')}"
            rows="3"
            name="specialRequirements"
            v-model="specialRequirements"
            placeholder=""
          ></textarea>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <documents :documentList="documentList"/>
    </div>
    <div class="column is-12">
      <div class="control">
        <button
          class="button button_ibs is-rounded sm_btn is-pulled-right"
          :class="{'is-loading': loading}"
          @click="confirmRequest"
          :disabled="loading"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Documents from '../../../components/services/Documents.vue'
export default {
  props: {
    supplierData: {
      type: Object,
      default: () => {}
    },
    myCustomerDetail: {
      type: Object,
      default: () => {}
    },
    sendRequestService: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    Documents
  },
  data () {
    return {
      supplierName: null,
      supplierContactPerson: null,
      supplierPersonalEmail: null,
      supplierPersonalPhone: null,
      supplierPersonalWechat: null,
      supplierCompanyPhone: null,
      supplierAddress: null,
      companyName: null,
      customerContactPerson: null,
      customerEmail: null,
      customerPhone: null,
      forwarderName: null,
      forwarderContactPerson: null,
      forwarderPersonalEmail: null,
      forwarderPersonalPhone: null,
      forwarderPersonalWechat: null,
      forwarderCompanyPhone: null,
      poNumber: null,
      totalCartonQuantity: null,
      typeQuantityContainers: null,
      loadingDate: null,
      productDescription: null,
      specialRequirements: null,
      localData: {},
      loading: false,
      documentList: []
    }
  },
  mounted () {
    this.supplierName = this.supplierData.name
    this.supplierContactPerson = this.supplierData.contact
    this.supplierPersonalEmail = this.supplierData.mail
    this.supplierPersonalPhone = this.supplierData.phone
    this.supplierAddress = this.supplierData.address
    this.companyName = this.myCustomerDetail.name
    this.customerContactPerson = this.myCustomerDetail.contact
    this.customerEmail = this.myCustomerDetail.email
    this.customerPhone = this.myCustomerDetail.phone
  },
  methods: {
    onlyNumbers (evt) {
      evt = (!evt) ? window.event : evt
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async confirmRequest () {
      const data = {
        supplierName: this.supplierName,
        supplierContactPerson: this.supplierContactPerson,
        supplierPersonalEmail: this.supplierPersonalEmail,
        supplierPersonalPhone: this.supplierPersonalPhone,
        supplierPersonalWechat: this.supplierPersonalWechat,
        supplierCompanyPhone: this.supplierCompanyPhone,
        supplierAddress: this.supplierAddress,
        companyName: this.companyName,
        customerContactPerson: this.customerContactPerson,
        customerEmail: this.customerEmail,
        customerPhone: this.customerPhone,
        forwarderName: this.forwarderName,
        forwarderContactPerson: this.forwarderContactPerson,
        forwarderPersonalEmail: this.forwarderPersonalEmail,
        forwarderPersonalPhone: this.forwarderPersonalPhone,
        forwarderPersonalWechat: this.forwarderPersonalWechat,
        forwarderCompanyPhone: this.forwarderCompanyPhone,
        poNumber: this.poNumber,
        totalCartonQuantity: this.totalCartonQuantity,
        typeQuantityContainers: this.typeQuantityContainers,
        loadingDate: this.loadingDate,
        productDescription: this.productDescription,
        specialRequirements: this.specialRequirements,
        documentList: this.documentList
      }
      this.loading = true
      if (await this.$validator.validateAll()) {
        await this.sendRequestService(data)
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
  .is-red {
    color: #ff3860;
  }
</style>
