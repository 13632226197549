<template>
  <div class="columns is-multiline">
    <modal-document
      v-if="modalDocumentActive"
      :close="activeModalDocument"
      :viewDocument="viewDocument"
    />
    <modal-confirm-delete
      v-if="modalDelete"
      :active="modalDelete"
      :close="confirmDelete"
      :documentRemove="documentRemove"
      :confirmationAction="confirmationAction"
      :loading="loading"
    />
    <div class="column is-12">
      <div class="box">
        <div class="columns is-multiline">
          <div class="column is-6">
            <h2 class="subtitle">
              Documentos Extra
            </h2>
          </div>
          <!-- Listado de Documentos -->
          <div class="column is-12">
            <div class="columns is-multiline is-mobile">
              <template v-if="availableDocuments && availableDocuments.length === 0">
                <div class="column is-centered has-text-centered">
                  <img class="imagen_error" src="@/assets/image/warnig-error.png" alt="Sin información">
                  <p class="has-text-centered isEmptyText">
                    Aún No Hay Documentos
                  </p>
                </div>
              </template>
              <template v-else>
                <div class="column is-3-desktop is-4-tablet is-6-mobile" v-for="(item, index) in availableDocuments" :key="index">
                  <div class="has_file">
                    <div>
                      <div class="image_content" @click="activeModalDocument(item.name)">
                        <img
                          class="image_pdf"
                          :src="require('@/assets/icon/' + imgUrl(item.name))"
                          alt="Archivo"
                        >
                        <p class="file_name">{{item.name}}</p>
                      </div>
                    </div>
                    <div class="icon_content">
                      <span
                        class="material-icons delete_icon is-danger"
                        @click="deleteMeThis(item.id)"
                      >
                        delete
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Subir documento -->
    <div class="column is-8" @dragover.prevent id="drop-form">
      <div class="box">
        <div class="columns is-multiline">
          <div class="column is-12">
            <h2 class="subtitle">
              Subir Archivo
            </h2>
          </div>
          <div class="column is-12">
            <div class="columns is-multiline is-centered">
              <div class="column is-8">
                <div class="columns is-multiline is-centered">
                  <div class="column is-8 has-text-centered">
                    <div class="field">
                      <div class="control">
                        <input
                          class="input is-rounded"
                          type="text"
                          :class="{'is-danger': vverrors.has('documentName')}"
                          v-model="documentName"
                          name="documentName"
                          placeholder="Nombre del Archivo"
                          v-validate="'required'"
                          :readonly="uploadType === 'archivo'"
                          :disabled="uploadType === 'archivo'"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="column is-12 has-text-centered">
                    <template v-if="uploadType === 'archivo'">
                    <form class="columns is-multiline is-centered">
                      <div class="column is-10">
                        <div class="field">
                          <div class="file is-large is-centered has-name is-boxed" :class="{'is-danger-outlined': vverrors.has('fileUpload')}">
                            <label class="file-label">
                              <input
                                id="fileUploadId"
                                name="fileUpload"
                                ref="fileUploadRef"
                                class="file-input"
                                type="file"
                                v-validate="'ext:jpeg,jpg,png,pdf,doc,docx,csv,xlsx,xls,xlsm'"
                                accept=".jpeg, .jpg, .png, .pdf, .doc, .docx, .csv, .xlsx, .xls, .xlsm"
                                @change="setFile"
                              >
                              <span class="file-cta">
                                <span v-if="this.file" class="material-icons has_file_input">
                                  check_circle_outline
                                </span>
                                <img v-else class="image_file_input" src="@/assets/icon/upload-icon.png" alt="Subir archivo">
                                <span class="file-label">
                                  {{ !this.file ? 'Sube o arrastra tu archivo.' : this.fileName }}
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                    </template>
                    <template v-else>
                      <div class="field">
                        <div class="control">
                          <input
                            class="input is-rounded input_ibs input-width"
                            :class="{'is-danger': vverrors.has('urlDocument')}"
                            type="text"
                            v-model="urlDocument"
                            name="urlDocument"
                            placeholder="Ingresa la URL"
                            v-validate="{ required: true, url: {require_protocol: true } }"
                          >
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="column is-10 has-text-centered">
                    <button
                      class="button button_ibs is-rounded sm_btn is-medium"
                      type="button"
                      @click="uploadFile"
                      :disabled="loadingButton || !file"
                      :class="{ 'is-loading': loadingButton }"
                    >
                      Subir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import mixins from '../../mixins/formats'

export default {
  name: 'Documents',
  mixins: [mixins],
  components: {
    ModalDocument: () => import('@/components/services/ModalDocument.vue'),
    ModalConfirmDelete: () => import('@/components/ModalConfirmDelete.vue')
  },
  computed: {
    ...mapState({
      authentication: state => state.authentication
    })
  },
  props: {
    serviceId: {
      type: Number
    },
    getStatus: {
      type: Function,
      default: () => {}
    },
    documentList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dragAndDropCapable: false,
      loading: false,
      loadingButton: false,
      loadingSend: false,
      modalResultActive: false,
      modalDocumentActive: false,
      modalDelete: false,
      documentRemove: null,
      viewDocument: null,
      isEmpty: 0,
      uploadType: 'archivo',
      urlDocument: null,
      file: null,
      fileName: null,
      documentName: null,
      availableDocuments: [],
      documentGroup: [],
      FILE_DOCUMENT: 1,
      URL_DOCUMENT: 2,
      finished: 2,
      files: []
    }
  },
  methods: {
    ...mapActions(['AddDocumentFile', 'AddDocumentFileExternal', 'deleteDocumentCustomer', 'updateStatusService', 'sendDocumentService', 'deleteDocument', 'deleteDocumentCustomerExternal']),
    determineDragAndDropCapable () {
    /*
      Create a test element to see if certain events
      are present that let us do drag and drop.
    */
      var div = document.createElement('div')
      /*
      Check to see if the `draggable` event is in the element
      or the `ondragstart` and `ondrop` events are in the element. If
      they are, then we have what we need for dragging and dropping files.

      We also check to see if the window has `FormData` and `FileReader` objects
      present so we can do our AJAX uploading
    */
      return (('draggable' in div) ||
            ('ondragstart' in div && 'ondrop' in div)) &&
            'FormData' in window && 'FileReader' in window
    },
    manageSelections (documentId) {
      this.changeStatusDocumentFile({ serviceId: this.serviceId, documentId })
      const exist = this.documentGroup.find(el => el === documentId)
      if (!exist) {
        this.documentGroup.push(documentId)
      } else {
        const indexElement = this.documentGroup.indexOf(documentId)
        delete this.documentGroup[indexElement]
      }
    },
    setFile (event) {
      if (event) {
        const [file] = event.target.files
        this.file = { key: event.target.name, file }
        console.log(this.file)
        this.fileName = file.name
        this.documentName = file.name
      }
    },
    cleanFile () {
      this.file = null
      this.fileName = null
      this.documentName = null
      this.urlDocument = null
    },
    async uploadFile () {
      if (await this.$validator.validateAll()) {
        this.loadingButton = true
        const document = {
          document: this.file,
          resultType: this.FILE_DOCUMENT,
          documentName: this.fileName
        }
        console.log('===>', this.authentication)
        const documentSaved = this.authentication ? await this.AddDocumentFile({ serviceId: this.serviceId, document }) : await this.AddDocumentFileExternal({ serviceId: this.serviceId, document })
        if (documentSaved.success) {
          this.availableDocuments.push({ id: documentSaved.documentId, name: documentSaved.documentName })
          this.documentList.push(documentSaved.documentId)
        }
        this.file = null
        this.fileName = null
        this.documentName = null
        this.loadingButton = false
      }
    },
    activeModalResult () {
      this.modalResultActive = !this.modalResultActive
    },
    activeModalDocument (document = null) {
      if (document) this.viewDocument = document
      console.log(this.viewDocument)
      this.modalDocumentActive = !this.modalDocumentActive
    },
    async updateStatusServiceAction () {
      this.loadingSend = true
      const data = {
        serviceId: this.serviceId,
        status: this.finished
      }
      const resp = await this.updateStatusService(data)
      this.loadingSend = false
      return resp
    },
    imgUrl (fileName) {
      const ext = fileName.substr(fileName.lastIndexOf('.') + 1)
      const excel = ['xlsx', 'xls', 'xlsm', 'csv']
      const image = ['jpeg', 'jpg', 'png']
      const doc = ['doc', 'docx']

      if (excel.includes(ext)) {
        return 'excel-file.png'
      } else if (image.includes(ext)) {
        return 'image-file.png'
      } else if (doc.includes(ext)) {
        return 'word-file.png'
      } else {
        return 'pdf-file.png'
      }
    },
    async deleteMeThis (id) {
      this.documentRemove = id
      console.log(this.documentRemove)
      this.confirmDelete()
    },
    confirmDelete () {
      this.modalDelete = !this.modalDelete
    },
    async confirmationAction (id) {
      this.loading = true
      this.authentication ? await this.deleteDocumentCustomer({ documentId: id }) : this.deleteDocumentCustomerExternal({ documentId: id })
      this.confirmDelete()
      this.availableDocuments = this.availableDocuments.filter(doc => doc.id !== id)
      this.loading = false
    }
  },
  beforeMount () {
  },
  mounted () {
    this.dragAndDropCapable = this.determineDragAndDropCapable()
    if (this.dragAndDropCapable) {
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
        document.getElementById('drop-form').addEventListener(evt, function (e) {
          document.getElementById('drop-form').style.background = '#00b00c'
          e.preventDefault()
          e.stopPropagation()
        }, false)
      })
      document.getElementById('drop-form').addEventListener('drop', function (e) {
        document.getElementById('drop-form').style.background = null
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          this.files.push(e.dataTransfer.files[i])
          // this.setFile(e)
          const [file] = e.dataTransfer.files
          this.file = { key: e.dataTransfer.name, file }
          this.fileName = file.name
          this.documentName = file.name
        }
      }.bind(this))
    }
  }
}
</script>

<style lang="scss" scoped>
  .box {
    padding: 2.5rem;
  }
  .has_file {
    display: flex;
  }
  .image_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 24px 0 rgba(0, 73, 136, 0.12);
    border: solid 1px #e1e5eb;
    background-color: #ffffff;
    max-width: 136.6px;
    min-width: 100px;
    margin: 0 1rem;
    height: 100%;
    cursor: pointer;
  }
  .image_pdf {
    max-width: 40px;
    padding: 2rem 0;
  }
  .image_content:hover .file_name {
    color: #3273dc;
  }
  .file_name {
    color: #0A0A0A;
    cursor: pointer;
    border-top: solid 1px #ebeded;
    padding: 0.5rem;
    font-size: 14px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    max-width: inherit;
    min-width: 136.6px;
  }
  .date_name {
    color: #0A0A0A;
    padding: 0.5rem;
    font-size: 12px;
    width: 100%;
  }
  .file_name a {
    color: #0A0A0A;
  }
  .input {
    font-size: 14px;
  }
  .input_ibs {
    margin: 2rem 0 3rem 0;
  }
  .is-medium {
    width: 196px;
  }
  .input-width {
    width: 300px;
  }

  .file-cta {
    background-color: white;
    color: #4a4a4a;
  }
  .file.is-boxed.has-name .file-cta {
    border-radius: 0px;
    min-width: 300px;
  }
  .file.is-boxed.has-name .file-name {
    border-radius: 0px;
  }
  .image_file_input {
    max-width: 60px;
  }
  .file-label {
    font-size: 16px;
    line-height: 1.54;
    text-align: center;
    color: #404254;
    padding-top: 1rem;
  }
  .is-danger-outlined .file-cta {
    border: 1px solid red;
  }
  .has_file_input {
    font-size: 60px;
    color: var(--primary-green);
  }
  .imagen_error {
    max-width: 80px;
    margin-bottom: 10px;
  }
  .isEmptyText {
    font-size: 14px;
    text-align: center;
    color: '#FAFAFA';
  }
  .delete_icon {
    font-size: 20px;
    cursor: pointer;
  }
</style>
