<template>
  <section class="columns is-flex is-vcentered empty-services">
    <div class="column is-12">
      <div class="has-text-centered">
        Selecciona un servicio y un proveedor
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotServices'
}
</script>

<style scoped>
  .empty-services {
    min-height: 180px;
  }
</style>
