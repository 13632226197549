<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <p class="parrafo mt-10">
        1.- <b>Send this request to your sales executive.</b> (Envíe este formato a su ejecutivo comercial).
      </p>
      <p class="parrafo mt-10">
        2.- <b>All the information that is filled out in this application must be in English language.</b>
        (Toda la información que se llene en esta solicitud debe estar en idioma inglés).
      </p>
      <p class="parrafo mt-10">
        3.- <b>Send a mail to the supplier informing them that Asia IBS will contact them to coordinate the
          visit.</b>
          (A su vez, envíe un correo electrónico a su proveedor indicando que "Asia IBS" lo contactará para programar
          la fecha de la visita).
      </p>
      <p class="parrafo mt-10">
        4.- <b>Any field with a "*" is required.</b> (Cada campo con un signo de "*"" es obligatorio).
      </p>
      <p class="parrafo mt-10">
        5.- <b>The report will be shared within 48 effective hours after the inspection.</b>
        (El reporte se entregará en un periodo de 48 hrs. hábiles después de hacerse la inspección).
      </p>
    </div>
    <div class="column is-12">
      <h2 class="subtitle">
        CONSIGNEE INFORMATION (Datos del consignatario)
      </h2>
    </div>
    <div class="column is-3">
      <label class="label">*Company name:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('companyName')}"
            type="text"
            name="companyName"
            v-model="companyName"
            placeholder="Company name"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Contact person:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('customerContactPerson')}"
            type="text"
            name="customerContactPerson"
            v-model="customerContactPerson"
            placeholder="Contact person"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">E-mail address:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('customerEmail')}"
            type="text"
            name="customerEmail"
            v-model="customerEmail"
            placeholder="E-mail address"
            v-validate="'required|email'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Telephone:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('customerPhone')}"
            type="text"
            name="customerPhone"
            v-model="customerPhone"
            placeholder="Telephone"
            v-validate="'required'"
            maxlength="15"
            @keypress="onlyNumbers()"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Confirmed with supplier:</label>
      <div class="field">
        <div class="control">
          <v-select
            class="input_select"
            :class="{ 'is-danger': vverrors.has('confirmedWithSupplier') }"
            label="name"
            placeholder='Confirmed with supplier'
            v-model="confirmedWithSupplier"
            name="confirmedWithSupplier"
            :reduce="confirm => confirm.id"
            :options="confirmList"
            v-validate="'required'"
          >
          </v-select>
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Need to be anonymous:</label>
      <div class="field">
        <div class="control">
          <v-select
            class="input_select"
            :class="{ 'is-danger': vverrors.has('needToBeAnonymus') }"
            label="name"
            placeholder='Need to be anonymous'
            v-model="needToBeAnonymus"
            name="needToBeAnonymus"
            :reduce="confirm => confirm.id"
            :options="confirmList"
            v-validate="'required'"
          >
          </v-select>
        </div>
      </div>
    </div>

    <div class="column is-12">
      <h2 class="subtitle">
        SUPPLIER INFORMATION (Datos del proveedor)
      </h2>
    </div>
    <div class="column is-3">
      <label class="label">*Supplier’s name:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierName')}"
            type="text"
            name="supplierName"
            v-model="supplierName"
            placeholder="Supplier’s name"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Supplier’s type:</label>
      <div class="field">
        <div class="control">
          <v-select
            class="input_select"
            :class="{ 'is-danger': vverrors.has('supplierType') }"
            label="name"
            placeholder='Supplier’s type'
            v-model="supplierType"
            name="supplierType"
            :reduce="supplierType => supplierType.id"
            :options="confirmList"
            v-validate="'required'"
          >
          </v-select>
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Contact person:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierContactPerson')}"
            type="text"
            name="supplierContactPerson"
            v-model="supplierContactPerson"
            placeholder="Contact person"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Personal e-mail:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierPersonalEmail')}"
            type="text"
            name="supplierPersonalEmail"
            v-model="supplierPersonalEmail"
            placeholder="Personal e-mail"
            v-validate="'required|email'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Personal phone:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierPersonalPhone')}"
            type="text"
            name="supplierPersonalPhone"
            v-model="supplierPersonalPhone"
            placeholder="Personal phone"
            v-validate="'required'"
            maxlength="15"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">[WeChat, QQ]:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierPersonalWechat')}"
            type="text"
            name="supplierPersonalWechat"
            v-model="supplierPersonalWechat"
            placeholder="WeChat, QQ"
            v-validate="'required'"
            maxlength="15"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Company e-mail:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierCompanyEmail')}"
            type="text"
            name="supplierCompanyEmail"
            v-model="supplierCompanyEmail"
            placeholder="Company e-mail"
            v-validate="'required|email'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">*Company phone:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierCompanyPhone')}"
            type="text"
            name="supplierCompanyPhone"
            v-model="supplierCompanyPhone"
            placeholder="Company phone"
            v-validate="'required'"
            maxlength="15"
            @keypress="onlyNumbers()"
          >
        </div>
      </div>
    </div>

    <div class="column is-3">
      <label class="label">*Address:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierAddress')}"
            type="text"
            name="supplierAddress"
            v-model="supplierAddress"
            placeholder="Address"
            v-validate="'required'"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Website:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierWebsite')}"
            type="text"
            name="supplierWebsite"
            v-model="supplierWebsite"
            placeholder="Website"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Industry:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierIndustry')}"
            type="text"
            name="supplierIndustry"
            v-model="supplierIndustry"
            placeholder="Industry"
          >
        </div>
      </div>
    </div>
    <div class="column is-3">
      <label class="label">Interested products:</label>
      <div class="field">
        <div class="control">
          <input
            class="input is-rounded input_ibs"
            :class="{'is-danger': vverrors.has('supplierInterestedProduct')}"
            type="text"
            name="supplierInterestedProduct"
            v-model="supplierInterestedProduct"
            placeholder="Interested products"
          >
        </div>
      </div>
    </div>

    <div class="column is-12">
      <h2 class="subtitle">
        SPECIAL REQUIREMENTS OR INSTRUCTIONS (Instrucciones o requerimientos específicos)
      </h2>
    </div>
    <div class="column is-12">
      <div class="field">
        <div class="control">
          <textarea
            class="textarea"
            :class="{'is-danger': vverrors.has('newMessage')}"
            rows="3"
            name="newMessage"
            placeholder=""
          ></textarea>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <documents :documentList="documentList"/>
    </div>
    <div class="column is-12">
      <div class="control">
        <button
          class="button button_ibs is-rounded sm_btn is-pulled-right"
          :class="{'is-loading': loading}"
          @click="confirmRequest"
          :disabled="loading"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Documents from '../../../components/services/Documents.vue'
export default {
  props: {
    supplierData: {
      type: Object,
      default: () => {}
    },
    myCustomerDetail: {
      type: Object,
      default: () => {}
    },
    sendRequestService: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    Documents
  },
  data () {
    return {
      confirmList: [
        { id: false, name: 'No' },
        { id: true, name: 'Si' }
      ],
      supplierName: null,
      supplierType: null,
      supplierContactPerson: null,
      supplierPersonalEmail: null,
      supplierPersonalPhone: null,
      supplierPersonalWechat: null,
      supplierCompanyPhone: null,
      supplierCompanyEmail: null,
      supplierAddress: null,
      supplierWebsite: null,
      supplierIndustry: null,
      supplierInterestedProduct: null,
      companyName: null,
      customerContactPerson: null,
      customerEmail: null,
      customerPhone: null,
      confirmedWithSupplier: null,
      needToBeAnonymus: null,
      specialRequirements: null,
      loading: false,
      documentList: []
    }
  },
  mounted () {
    this.supplierName = this.supplierData.name
    this.supplierContactPerson = this.supplierData.contact
    this.supplierPersonalEmail = this.supplierData.mail
    this.supplierPersonalPhone = this.supplierData.phone
    this.supplierAddress = this.supplierData.address
    this.companyName = this.myCustomerDetail.name
    this.customerContactPerson = this.myCustomerDetail.contact
    this.customerEmail = this.myCustomerDetail.email
    this.customerPhone = this.myCustomerDetail.phone
  },
  methods: {
    onlyNumbers (evt) {
      evt = (!evt) ? window.event : evt
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async confirmRequest () {
      const data = {
        supplierName: this.supplierName,
        supplierType: this.supplierType,
        supplierContactPerson: this.supplierContactPerson,
        supplierPersonalEmail: this.supplierPersonalEmail,
        supplierPersonalPhone: this.supplierPersonalPhone,
        supplierPersonalWechat: this.supplierPersonalWechat,
        supplierCompanyPhone: this.supplierCompanyPhone,
        supplierCompanyEmail: this.supplierCompanyEmail,
        supplierAddress: this.supplierAddress,
        supplierWebsite: this.supplierWebsite,
        supplierIndustry: this.supplierIndustry,
        supplierInterestedProduct: this.supplierInterestedProduct,
        companyName: this.companyName,
        customerContactPerson: this.customerContactPerson,
        customerEmail: this.customerEmail,
        customerPhone: this.customerPhone,
        confirmedWithSupplier: this.confirmedWithSupplier,
        needToBeAnonymus: this.needToBeAnonymus,
        specialRequirements: this.specialRequirements,
        documentList: this.documentList
      }
      this.loading = true
      if (await this.$validator.validateAll()) {
        await this.sendRequestService(data)
      }
      this.loading = false
    }
  }
}
</script>
